
import { StoreFront } from 'eli-ui-lib';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { SharedDataService } from 'elbuild-ui-lib-core';

@Injectable({
  providedIn: 'root'
})
export class StorefrontService {


  private _storefront = new BehaviorSubject<StoreFront>(undefined);
  currentstorefront = this._storefront.asObservable();





  constructor(
    private http: HttpClient,
    private shareddataservice: SharedDataService
  ) {


  }

  public getStorefront() {
    this.http.get('/ws/storefront/current').pipe(map((res: any) => plainToInstance(StoreFront, res))).subscribe({
      next: (s: StoreFront) => {
        this._storefront.next(s);
      },
      error: (err: any) => {
        console.error(err);
      }
    });

  }
}
